import { useState, useEffect } from 'react'
import CentralBlock from './CentralBlock'
import '../styles/App.css'
import BackgroundVid from './BackgroundVid'
import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { record } from 'aws-amplify/analytics'
import amplifyconfig from '../amplifyconfiguration.json'
import { Amplify } from 'aws-amplify'
import { configureAutoTrack } from 'aws-amplify/analytics'

Amplify.configure(amplifyconfig)

configureAutoTrack({
	// REQUIRED, turn on/off the auto tracking
	enable: true,
	// REQUIRED, the event type, it's one of 'event', 'pageView' or 'session'
	type: 'event',
	// OPTIONAL, additional options for the tracked event.
	options: {
		// OPTIONAL, the attributes of the event
		attributes: {
			customizableField: 'attr',
		},
	},
})

// Determine user's preferred language immediately before component mounts
const defaultLanguage = () => {
	const browserLang =
		(navigator.languages && navigator.languages[0]) ||
		navigator.language ||
		navigator.userLanguage
	// Default to English if user's language is not French
	return browserLang.startsWith('fr') ? 'fr' : 'en'
}

// Function to check if IP is from Quebec and adjust language if necessary
const getLanguageFromIP = async (setCurrentLang, alwaysRun) => {
	try {
		const response = await fetch('https://ipapi.co/json/')
		if (response.ok) {
			const { region_code } = await response.json()
			if (region_code === 'QC') {
				// If the IP is located in Quebec, set language to French
				setCurrentLang('fr')
			}
		}
	} catch (error) {
		console.error('Failed to fetch IP location:', error)
	} finally {
		// This block runs whether the fetch succeeds or fails
		alwaysRun()
	}
}

function App() {
	const [lang, updateLang] = useState(defaultLanguage())
	const [isLangSet, setIsLangSet] = useState(false)

	// Effect for setting language from IP, runs only once after the component mounts
	useEffect(() => {
		getLanguageFromIP(
			(lang) => {
				updateLang(lang)
			},
			() => {
				setIsLangSet(true) // This will be called regardless of fetch success
			}
		)
	}, []) // Empty dependency array means this runs once on mount

	useEffect(() => {
		record({ name: 'AppVisit' })
	}, [])

	function getRandomButtonColor() {
		// Array of color options
		const colors = [
			'rgb(160, 176, 212, 0.9)',
			'rgb(96, 175, 124, 0.9)',
			'rgb(238, 128, 93, 0.9)',
			'rgb(251, 213, 98, 0.9)',
			'rgb(203, 194, 255, 0.9)',
			'rgb(219, 106, 140, 0.9)',
			'rgb(172, 177, 0, 0.9)',
		]

		// Randomly select a color
		const randomIndex = Math.floor(Math.random() * colors.length)
		return colors[randomIndex]
	}
	const buttonColor = getRandomButtonColor()

	return (
		<React.Fragment>
			<BackgroundVid />
			<Header lang={lang} updateLang={updateLang} />
			<CentralBlock
				lang={lang}
				buttonColor={buttonColor}
				isVisible={isLangSet}
			/>
			{isLangSet && <Footer lang={lang} buttonColor={buttonColor} />}
		</React.Fragment>
	)
}

export default App
