import { vidList } from '../datas/vids'
import React from 'react'

function BackgroundVidComponent() {
	// Grab a random video from the list
	const cdn_public = 'https://d4ybyhnmc714f.cloudfront.net'
	const randomIndex = Math.floor(Math.random() * vidList.length)
	const randomVideo = vidList[randomIndex]
	const finalVid = `${cdn_public}/bg_vids/${randomVideo}.mp4`
	const placeholderImage = `${cdn_public}/bg_vids/vids_placeholder/${randomVideo}.png`

	return (
		<div id='bg-bg-vid'>
			<video
				autoPlay
				muted
				loop
				id='bg-vid'
				src={finalVid}
				poster={placeholderImage}
				style={{
					width: '100%',
					height: '100%',
				}}
			></video>
		</div>
	)
}

const BackgroundVid = React.memo(BackgroundVidComponent)

export default BackgroundVid
