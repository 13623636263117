export const vidList = [
	'cdp1',
	'cdp2',
	'm-r1',
	'm-r2',
	'm-r3',
	'm-r4',
	'maisonneuve',
	'metro_laurier',
	'metro',
	'parc_ave',
	'rachel',
	'st-denis',
	'university',
]
