export const citiesList = {
	fr: {
		Québec: [
			{ city: 'Montréal', url: 'https://montreal.curbcut.ca' },
			{ city: 'Laval', url: 'https://laval.curbcut.ca' },
		],
		Ontario: [{ city: 'Toronto', url: 'https://toronto.curbcut.ca' }],
		'Colombie-Britannique': [
			{ city: 'Vancouver', url: 'https://vancouver.curbcut.ca' },
			{ city: 'Kelowna', url: 'https://kelowna.curbcut.ca' },
			{ city: 'Comox Valley', url: 'https://comoxvalley.curbcut.ca' },
			{ city: 'Prince George', url: 'https://princegeorge.curbcut.ca' },
			{ city: 'Fraser Valley', url: 'https://fraservalley.curbcut.ca' },
		],
	},
	en: {
		Quebec: [
			{ city: 'Montreal', url: 'https://montreal.curbcut.ca' },
			{ city: 'Laval', url: 'https://laval.curbcut.ca' },
		],
		Ontario: [{ city: 'Toronto', url: 'https://toronto.curbcut.ca' }],
		'British Columbia': [
			{ city: 'Vancouver', url: 'https://vancouver.curbcut.ca' },
			{ city: 'Kelowna', url: 'https://kelowna.curbcut.ca' },
			{ city: 'Comox Valley', url: 'https://comoxvalley.curbcut.ca' },
			{ city: 'Prince George', url: 'https://princegeorge.curbcut.ca' },
			{ city: 'Fraser Valley', url: 'https://fraservalley.curbcut.ca' },
		],
	},
}
