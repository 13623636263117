import '../styles/Footer.css'
import SocialMediaIcons from './SocialMediaIcons'
import { useState } from 'react'

function Footer({ lang, buttonColor }) {
	const [isHovered, setIsHovered] = useState(false)
	// Define the default and hover/focus styles
	const defaultStyle = {
		backgroundColor: buttonColor,
	}
	const hoverFocusStyle = {
		backgroundColor: 'rgba(255, 255, 255, 1)',
	}

	return (
		<div className='footer'>
			<div className='footer-inside'>
				<div className='newsletter-form'>
					<form
						action='https://curbcut.us11.list-manage.com/subscribe/post?u=b9df261ebcf34acc88a4aab38&amp;id=5187f08559&amp;f_id=000196e0f0'
						method='post'
						id='mc-embedded-subscribe-form'
						name='mc-embedded-subscribe-form'
						className='validate'
						target='_blank'
					>
						<input
							type='email'
							name='EMAIL'
							className='required email'
							id='mce-EMAIL'
							placeholder={
								lang === 'en'
									? 'Email address'
									: 'Adresse courriel'
							}
							required
						/>
						<button
							type='submit'
							className='submit-btn'
							style={isHovered ? hoverFocusStyle : defaultStyle}
							onMouseEnter={() => setIsHovered(true)}
							onMouseLeave={() => setIsHovered(false)}
							onFocus={() => setIsHovered(true)}
							onBlur={() => setIsHovered(false)}
						>
							{lang === 'en'
								? 'Get updates'
								: "S'inscrire à l'infolettre"}
						</button>
					</form>
				</div>
				<div className='socials'>
					<SocialMediaIcons />
				</div>
			</div>
		</div>
	)
}

export default Footer
