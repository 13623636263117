import '../styles/Header.css'

function getButtonLabel(lang) {
	return lang === 'en' ? 'FR' : 'EN'
}

function LangButtons({ lang, updateLang }) {
	const toggleLang = () => {
		if (lang === 'en') {
			updateLang('fr')
		} else {
			updateLang('en')
		}
	}

	return (
		<div>
			<button className='lang_btn header-elements' onClick={toggleLang}>
				{getButtonLabel(lang)}
			</button>
		</div>
	)
}

export default LangButtons
