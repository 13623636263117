import React, { useState } from 'react'
import '../styles/CentralBlock.css'
import { citiesList } from '../datas/citiesList'
import { record } from 'aws-amplify/analytics'

function CentralBlock({ lang, buttonColor, isVisible }) {
	const [menuVisible, setMenuVisible] = useState(false)
	const [selectedProvince, setSelectedProvince] = useState(null)
	const [isHovered, setIsHovered] = useState(false)

	// Style definitions
	const defaultButtonStyle = { backgroundColor: buttonColor }
	const hoverFocusStyle = { backgroundColor: 'rgba(255, 255, 255, 1)' }

	// Localization
	const slogan =
		lang === 'en'
			? 'Your gateway to an in-depth and interactive understanding of urban sustainability'
			: 'Votre portail pour une compréhension approfondie et interactive de la durabilité urbaine'

	const exploreButtonText =
		lang === 'en' ? 'Explore our cities' : 'Explorez nos villes'

	// Handlers
	const toggleMenuVisibility = () => setMenuVisible(!menuVisible)
	const handleProvinceClick = (provinceName) => {
		setSelectedProvince(
			selectedProvince === provinceName ? null : provinceName
		)
		record({ name: 'ProvincePick', attributes: { province: provinceName } })
	}
	const recordCityPick = (cityName, url, event) => {
		event.preventDefault() // Prevent default anchor behavior
		record({ name: 'CityPick', attributes: { city: cityName } })

		// Assuming a brief delay allows the event to be sent
		setTimeout(() => {
			window.location.href = url // Redirect after a short delay
		}, 300) // Adjust the delay as needed
	}

	return (
		<div className='central-block'>
			<div
				className={`inner-block ${
					menuVisible || !isVisible ? 'hidden' : 'visible'
				}`}
			>
				<h1 className='slogan'>{slogan}</h1>
				<div
					className='choose-your-city-btn'
					style={isHovered ? hoverFocusStyle : defaultButtonStyle}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
					onFocus={() => setIsHovered(true)}
					onBlur={() => setIsHovered(false)}
					onClick={toggleMenuVisibility}
				>
					{exploreButtonText}
				</div>
			</div>
			<div
				className={`inner-block ${menuVisible ? 'visible' : 'hidden'}`}
			>
				<div
					className='back-button'
					onClick={() => setMenuVisible(false)}
					style={{ color: buttonColor }}
				>
					{lang === 'en' ? 'Back' : 'Retour'}
				</div>
				<div className='city-menu'>
					{Object.keys(citiesList[lang]).map((province) => (
						<div key={province} className='province-item'>
							<div
								className='province-name'
								style={{ transition: 'all 0.3s ease-in-out' }}
								onMouseEnter={(e) => {
									e.currentTarget.style.color = buttonColor
									e.currentTarget.style.transform =
										'scale(1.05)'
								}}
								onMouseLeave={(e) => {
									e.currentTarget.style.color = ''
									e.currentTarget.style.transform = ''
								}}
								onClick={() => handleProvinceClick(province)}
							>
								{province}
							</div>
							{selectedProvince === province && (
								<div className='cities-list'>
									{citiesList[lang][province].map((city) => (
										<a
											key={city.city}
											href={city.url}
											className='city-name'
											onMouseEnter={(e) =>
												(e.currentTarget.style.color =
													buttonColor)
											}
											onMouseLeave={(e) =>
												(e.currentTarget.style.color =
													'')
											}
											onClick={(e) =>
												recordCityPick(
													city.city,
													city.url,
													e
												)
											} // Modified line
										>
											{city.city}
										</a>
									))}
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default CentralBlock
