import React from 'react'
import { SocialIcon } from 'react-social-icons'

const SocialMediaIcons = () => {
	return (
		<div className='social-media-icons'>
			<SocialIcon
				network='github'
				bgColor='transparent'
				url='https://github.com/Curbcut'
				target='_blank'
				rel='noopener noreferrer'
			/>
			<SocialIcon
				network='youtube'
				bgColor='transparent'
				url='https://www.youtube.com/channel/UCAegqHskF5M7rt80VX2CXaQ'
				target='_blank'
				rel='noopener noreferrer'
			/>
			<SocialIcon
				network='linkedin'
				bgColor='transparent'
				url='https://www.linkedin.com/company/curbcut/'
				target='_blank'
				rel='noopener noreferrer'
			/>
			<SocialIcon
				network='instagram'
				bgColor='transparent'
				url='https://www.instagram.com/curbcut_ca/'
				target='_blank'
				rel='noopener noreferrer'
			/>
			<SocialIcon
				network='threads'
				bgColor='transparent'
				url='https://www.threads.net/@curbcut_ca'
				target='_blank'
				rel='noopener noreferrer'
			/>
			<SocialIcon
				network='x'
				bgColor='transparent'
				url='https://twitter.com/curbcut_ca'
				target='_blank'
				rel='noopener noreferrer'
			/>
		</div>
	)
}

export default SocialMediaIcons
