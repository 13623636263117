import '../styles/Header.css'
import LangButtons from './LangButtons'
// import logo from '../assets/Curbcut-logo-black.png'
import logo from '../assets/Curbcut-logo-white.svg'

function Header({ lang, updateLang }) {
	return (
		<div className='header'>
			<div className='header-inside'>
				<img src={logo} className='cc-logo' alt='Curbcut logo' />
				<LangButtons lang={lang} updateLang={updateLang} />
			</div>
		</div>
	)
}

export default Header
